@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Inter";
    src: url("/public/fonts/Inter-Regular.woff") format("woff");
  }

  @font-face {
    font-family: "Inter Bold";
    font-weight: 700;
    src: url("/public/fonts/Inter-Bold.woff") format("woff");
  }

  @font-face {
    font-family: "Inter Semi Bold";
    src: url("/public/fonts/Inter-SemiBold.woff") format("woff");
  }

  @font-face {
    font-family: "Inter Medium";
    font-weight: 500;
    src: url("/public/fonts/Inter-Medium.woff") format("woff");
  }

  body {
    font-family: "Inter", "Arial", sans-serif;
  }

  ul {
    @apply ml-8;
  }
  li {
    @apply pl-2 my-1;
  }
}

/* Hide increment, decrement in number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@layer components {
  /* .carousel-dots button::before {
    @apply !outline !outline-black !outline-1 rounded-full !text-white !h-[6px] !w-[6px]
  } */

  .carousel-dots button::before {
    @apply !text-[8px];
  }

  .loading-dots {
    @apply text-center w-full;
  }

  .spinner {
    @apply inline-block;
  }
}

@keyframes dot-keyframes {
  0% {
    opacity: 0.3;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1, 1);
  }

  100% {
    opacity: 0.3;
    transform: scale(1, 1);
  }
}

.dot {
  animation: dot-keyframes .75s infinite ease-in-out;
  background-color: #777;
  border-radius: 10px;
  display: inline-block;
  height: 8px;
  width: 8px;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner:after {
  content: ' ';
  display: block;
  border-radius: 50%;
  border: 2px solid white;
  animation: spinner 1.2s linear infinite;
}
